.hero-banner {
  background-image: url('./assets/images/aicloud.jpeg');
  background-size: cover;
  background-position: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  position: relative;
  height: 400px;
  text-align: left;
  padding: 20px;
}

.hero-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Lighter overlay */
  z-index: 1;
}

.hero-banner h1,
.hero-banner p,
.hero-banner .hero-button {
  position: relative;
  z-index: 2;
}

.hero-banner .hero-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #007bff; /* Example button color */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
